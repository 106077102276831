export function formatCurrency (price, curr = 'eur') {
  const currency = curr.toUpperCase()
  const number = price / 100

  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    trailingZeroDisplay: 'stripIfInteger',
    currency
  }).format(number)
}
